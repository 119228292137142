// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // akid: 'AKIA6JY5OJGA337SDUMZ',
  // skid: 'ckdz6zoRmNQCrOMp2mZ6BXsTWdfAjETXmaL5EJaW',
  // read_akid: "AKIA6JY5OJGAUMHRLOGE",
  // read_skid: "+nHwKlRfh9/p6ic4uYHMoMrqIilThI2tSLNXxfVY",
  akid: "AKIA6JY5OJGA2XTCAFHP",
  skid: "qLx8nf3WrXp9hX15+kfKOGAWZY25vtlTl5kdZ8Lr",
  read_akid: "AKIA6JY5OJGA5BBNVGXP",
  read_skid: "KRIOHpf+F/Gkw1PfdsQGl9Sb8iunR4HyfiTMOQ41",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
