<nav class="navbar navbar-expand-sm sticky-top px-3 navbar-light" *ngIf="width >= 916">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div>
      <a href="/home" *ngIf="allinOneService.currentDomainType == '001'">
        <img src="assets/images/khub.png" alt="" class="navbar-brand-logo" />
      </a>
      <a href="/home" *ngIf="allinOneService.currentDomainType != '001'">
        <img [src]="allinOneService.channeldata.url" alt="" class="navbar-brand-logo" />
      </a>
      <!-- <span class="type">Instructor</span> -->
    </div>

    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
      <div class="search">
        <i class="fa fa-search"></i>
        <input type="text" class="form-control" id="coursesubname" placeholder="Search" autocomplete="off"
          [(ngModel)]="searchTerm" (keyup.enter)="goToSearchPage()" />
      </div>
      <ul class="navbar-nav mr-auto">
        <div *ngIf="allinOneService.channels.length > 1 &&  allinOneService.currentDomainType == '001'"
          class="category-dropdown" [matMenuTriggerFor]="menu1">
          <a class="">{{
            allinOneService.selectedChannelName
            }}</a>
        </div>

        <div class="category-dropdown" [matMenuTriggerFor]="menu" #catmenutrigger>
          <a class="">Categories</a>
        </div>

        <mat-menu #menu1="matMenu" class="category-menu">
          <div>
            <div *ngIf="errMsg == ''">
              <button mat-menu-item *ngFor="let chl of allinOneService.channels" (click)="channelClick(chl)">
                <img [src]="
                    chl.url == ''
                      ? 'https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:340,height:192/https://www.filepicker.io/api/file/VnHPUS7BQnKCiVqglcAL'
                      : chl.url
                  " alt="" width="50" height="35px" class="me-2" style="object-fit: cover;" /><span>{{ chl.name
                  }}</span>
              </button>
            </div>
            <div *ngIf="errMsg != ''" class="error-container">
              <p style="margin: 10px">Something went wrong!, please reload</p>
              <br />

              <div>
                <button type="button" (click)="reload()" class="btn btn-outline-secondary">
                  Reload
                </button>
              </div>
            </div>
          </div>

          <!-- loading -->
          <div *ngIf="gettingChannel" class="p-2">
            <ngx-skeleton-loader [theme]="{ height: '30px', width: '100%' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ height: '30px', width: '100%' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ height: '30px', width: '100%' }"></ngx-skeleton-loader>
          </div>
        </mat-menu>

        <mat-menu #menu="matMenu" class="category-menu">
          <div>
            <div *ngIf="errMsg == ''">
              <ng-container *ngFor="let category of allinOneService.categorieswithcounts">
                <button mat-menu-item [matMenuTriggerFor]="subcat" (click)="goToCourseByCategoryPage(category)"
                  *ngIf="category.subcategorylist && category.subcategorylist.length > 0">
                  {{ category.categoryname }}
                  <!-- ({{ category.count }}) -->

                  <mat-menu #subcat="matMenu" class="subcategory-menu">
                    <button mat-menu-item *ngFor="let subcategory of category.subcategorylist"
                      (click)="goToCourseBySubCategoryPage(category,subcategory)">
                      {{ subcategory.subcategoryname }}
                    </button>
                  </mat-menu>
                </button>
                <button mat-menu-item (click)="goToCourseByCategoryPage(category)"
                  *ngIf="!category.subcategorylist || (category.subcategorylist && category.subcategorylist.length == 0)">
                  {{ category.categoryname }}
                  <!-- ({{ category.count }}) -->
                </button>
              </ng-container>


            </div>

            <div *ngIf="errMsg != ''" class="error-container">
              <p style="margin: 10px">Something went wrong!, please reload</p>
              <br />

              <div>
                <button type="button" (click)="reload()" class="btn btn-outline-secondary">
                  Reload
                </button>
              </div>
            </div>
          </div>

          <!-- loading -->
          <div *ngIf="gettingCategory" class="p-2">
            <ngx-skeleton-loader [theme]="{ height: '30px', width: '100%' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ height: '30px', width: '100%' }"></ngx-skeleton-loader>
            <ngx-skeleton-loader [theme]="{ height: '30px', width: '100%' }"></ngx-skeleton-loader>
          </div>
        </mat-menu>
        
        <!-- <li class="nav-item" *ngIf="authenticate">
          <a class="nav-link active" [ngClass]="{ 'active-color': activeSchedule}" aria-current="page" (click)="schedule()">Schedule</a>
        </li> -->
        
        <!-- <li class="nav-item" *ngIf="authenticate">
          <a class="nav-link active" [ngClass]="{ 'active-color': activeMyCourse}" aria-current="page" (click)="myCourses()">My Courses</a>
        </li> -->

        <li class="nav-item" *ngIf="authenticate">
          <a class="nav-link active" routerLink="home/courses/schedule" routerLinkActive="active-color" [routerLinkActiveOptions]="{exact:
            true}" aria-current="page">Schedule</a>
        </li>

        <li class="nav-item" *ngIf="authenticate">
          <a class="nav-link active" routerLink="home/courses" routerLinkActive="active-color" [routerLinkActiveOptions]="{exact:
            true}" aria-current="page">My Courses</a>
        </li>
      </ul>
      <button *ngIf="!authenticate" mat-stroked-button color="primary" class="mx-3 sign-in-btn" (click)="login()">
        Sign In
      </button>
      <div *ngIf="authenticate">

        <div [ngClass]="{ 'img-profile': user.profileimage == ''}" [matMenuTriggerFor]="menu">
          <img class="profileimage" *ngIf="user.profileimage != ''" [src]="allinOneService.imgurl + user.profileimage"
            alt="">
          <span *ngIf="user.profileimage == ''"> {{ user.username ? user.username.charAt(0) :
            user.userid.charAt(0)}}</span>
        </div>


        <mat-menu #menu="matMenu" class="profile-menu">
          <div class="img-wrapper">
            <div [ngClass]="{ 'menu-img-profile': user.profileimage == ''}">
              <span *ngIf="user.profileimage == ''"> {{ user.username.charAt(0)}}</span>
              <img class="profileimage menu-img-profile" *ngIf="user.profileimage != ''"
                [src]="allinOneService.imgurl + user.profileimage" alt="">

            </div>
            <div class="profile-name mx-2">
              <strong> {{user.username}} </strong>
              <span> {{user.userid}} </span>
            </div>
          </div>
          <!-- <mat-divider></mat-divider> -->
          <div class="profile-footer">
            <div class="signout-btn" routerLink="/home/user/analytics">
              <img [src]="analyticIcon" alt="analytics" class="profile-item-icon">
              <span class="profile-item-label">Analytics</span>
            </div>
            <div class="signout-btn" (click)="openapp()" *ngIf="allinOneService.showStudioLink">
              <img src="assets/images/studio.png" alt="KHub Studio" class="profile-item-icon">
              <span class="profile-item-label">KHub Studio</span>
            </div>
            <div class="signout-btn" (click)="allinOneService.logout()">
              <img src="assets/images/signout.png" alt="Instructor" class="profile-item-icon">
              <span class="profile-item-label">Sign Out</span>
            </div>
          </div>
          <!-- <button mat-menu-item class="signout-btn" autofocus="false" (click)="allinOneService.logout()">Sign out</button> -->
        </mat-menu>
      </div>

      <!-- <button (click)="allinOneService.logout()" *ngIf="authenticate" mat-stroked-button color="primary" class="mx-3">
        Sign Out
      </button> -->
    </div>
  </div>
</nav>


<!-- Mobile View Nab Bar -->
<nav class="navbar navbar-expand-md sticky-top px-3 navbar-light" *ngIf="width <= 916 && authenticate">
  <div class="w-100">
    <div *ngIf="authenticate">
      <div class="d-flex justify-content-between align-items-center">
        <a href="/home" *ngIf="allinOneService.currentDomainType == '001'">
          <img src="assets/images/khub.png" alt="" class="navbar-brand-logo nav-mobile" />
        </a>
        <a href="/home" *ngIf="allinOneService.currentDomainType != '001'">
          <img [src]="allinOneService.channeldata.url" alt="" class="navbar-brand-logo nav-mobile" />
        </a>
        <ul class="navbar-nav mr-auto">

          <div *ngIf="allinOneService.channels.length > 1 &&  allinOneService.currentDomainType == '001'"
            class="category-dropdown" [matMenuTriggerFor]="menu1">
            <a class="">{{
              allinOneService.selectedChannelName
              }}</a>
          </div>
          <div *ngIf="allinOneService.currentDomainType != '001'">
            <a class="">{{
              allinOneService.selectedChannelName
              }}</a>
          </div>

          <mat-menu #menu1="matMenu" class="category-menu">
            <div>
              <div *ngIf="errMsg == ''">
                <button mat-menu-item *ngFor="let chl of allinOneService.channels" (click)="channelClick(chl)">
                  <img [src]="
                      chl.url == ''
                        ? 'https://process.fs.teachablecdn.com/ADNupMnWyR7kCWRvm76Laz/resize=width:340,height:192/https://www.filepicker.io/api/file/VnHPUS7BQnKCiVqglcAL'
                        : chl.url
                    " alt="" width="50" height="35px" class="me-2" style="object-fit: cover;" /><span>{{ chl.name
                    }}</span>
                </button>
              </div>
              <div *ngIf="errMsg != ''" class="error-container">
                <p style="margin: 10px">Something went wrong!, please reload</p>
                <br />

                <div>
                  <button type="button" (click)="reload()" class="btn btn-outline-secondary">
                    Reload
                  </button>
                </div>
              </div>
            </div>

            <!-- loading -->
            <div *ngIf="gettingChannel" class="p-2">
              <ngx-skeleton-loader [theme]="{ height: '30px', width: '100%' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader [theme]="{ height: '30px', width: '100%' }"></ngx-skeleton-loader>
              <ngx-skeleton-loader [theme]="{ height: '30px', width: '100%' }"></ngx-skeleton-loader>
            </div>
          </mat-menu>
        </ul>


        <div [ngClass]="{ 'img-profile': user.profileimage == ''}" [matMenuTriggerFor]="menu">
          <img class="profileimage" *ngIf="user.profileimage != ''" [src]="allinOneService.imgurl + user.profileimage"
            alt="">
          <span *ngIf="user.profileimage == ''"> {{ user.username ? user.username.charAt(0) :
            user.userid.charAt(0)}}</span>
        </div>
        <mat-menu #menu="matMenu" class="profile-menu">
          <div class="img-wrapper">
            <div [ngClass]="{ 'menu-img-profile': user.profileimage == ''}">
              <span *ngIf="user.profileimage == ''"> {{ user.username.charAt(0)}}</span>
              <img class="profileimage menu-img-profile" *ngIf="user.profileimage != ''"
                [src]="allinOneService.imgurl + user.profileimage" alt="">

            </div>
            <div class="profile-name mx-2">
              <strong> {{user.username}} </strong>
              <span> {{user.userid}} </span>
            </div>
          </div>
          <!-- <mat-divider></mat-divider> -->
          <div class="profile-footer">
            <div class="signout-btn" routerLink="/home/user/analytics">
              <img [src]="analyticIcon" alt="analytics" class="profile-item-icon">
              <span class="profile-item-label">Analytics</span>
            </div>
            <!-- <div class="signout-btn" (click)="openapp()">
              <img src="assets/images/studio.png" alt="KHub Studio" class="profile-item-icon">
              <span class="profile-item-label">KHub Studio</span>
            </div> -->
            <div class="signout-btn" (click)="allinOneService.logout()">
              <img src="assets/images/signout.png" alt="Instructor" class="profile-item-icon">
              <span class="profile-item-label">Sign Out</span>
            </div>
          </div>
          <!-- <button mat-menu-item class="signout-btn" autofocus="false" (click)="allinOneService.logout()">Sign out</button> -->
        </mat-menu>
      </div>


    </div>
  </div>
</nav>