import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import * as S3 from 'aws-sdk/clients/s3';
import * as XLSX from 'xlsx';
import * as CryptoJS from 'crypto-js';
import imageCompression from 'browser-image-compression';
import * as FileSaver from 'file-saver';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AllInOneService {

  // akid: string = '';
  // skid: string = '';

  imgurl: string = '';
  channelImageUrl: string = '';
  docurl: string = '';
  input_err_msg: string = '';
  fileurl: string = '';

  // studio
  iamdomain: string =     "";
  domain: string =        '';
  kunyekdomain: string =  '';

  // old domains
  // domain: string =       'https://api1.khub.omnicloudapi.com/';
  // kunyekdomain: string = 'https://api1.ky.omnicloudapi.com/';
  // iamdomain: string =    'https://api1.iam.omnicloudapi.com/';

  defaultImg: string = '';
  socketUrl : string = '';
  adminUrl: string = "";
  courseUrl: string = "";
  sectionUrl: string = "";
  subsectionUrl: string = "";
  unitUrl: string = "";
  studentUrl: string = "";
  categoryUrl: string = "";
  channelUrl: string = "";
  userUrl: string = "";
  reportUrl: string = "";


  instrucotrId = '9999';
  selectedChannelid = '';

  user: any;

  policy_menus: Object[] = [];
  adminChannels: Object[] = [];
  supervisorChannels: Object[] = [];
  hradminChannels: Object[] = [];

  acc_key: string = environment.akid;
  sec_key: string = environment.skid;


  private region: string = 'ap-southeast-1';
  private secretKey: string = '!@#$29!@#$Gk**&*';

  adminmail: string = 'learn.kunyek@gmail.com';
  isApproved: boolean = true;
  hideNavbar: boolean = false;
  unauthorizedMsg: string = 'Your account has not been approved yet.';

  private encrypted_userid: string = this.encrypt('userid');
  private encrypted_kunyek_userid: string = this.encrypt('kunyek_userid');
  private encrypted_kunyek_password: string = this.encrypt('kunyek_password');
  private encrypted_atoken: string = this.encrypt('atoken');
  private encrypted_appid: string = this.encrypt('appid');
  private encrypted_role: string = this.encrypt('role');

  datePickerConfig = {
    dateInputFormat: 'DD/MM/YYYY',
    showWeekNumbers: false,
  };

  constructor(
    private router: Router, 
    private cookieService: CookieService,
    private http: HttpClient
  ) {}

  loadConfig() {
    return this.http.get('/assets/config/config.json');
  }

  getUserId() {
    const decrypted = this.cookieService.get(this.encrypted_userid) || '';
    return this.decrypt(decrypted);
  }
  getSession() {
    return sessionStorage.getItem('session') || '';
  }
  getKunyekUserId() {
    const decrypted =
      this.cookieService.get(this.encrypted_kunyek_userid) || '';
    return this.decrypt(decrypted);
  }
  getKunyekPassword() {
    const decrypted =
      this.cookieService.get(this.encrypted_kunyek_password) || '';
    return this.decrypt(decrypted);
  }
  getAtoken() {
    const decrypted = this.cookieService.get(this.encrypted_atoken) || '';
    return this.decrypt(decrypted);
  }
  getAppId() {
    const decrypted = this.cookieService.get(this.encrypted_appid);
    return this.decrypt(decrypted) || 'kunyek';
  }
  getUserRole() {
    const decrypted = this.cookieService.get(this.encrypted_role) || '1';
    return this.decrypt(decrypted);
  }

  logout() {
    this.cookieService.deleteAll('/');
    sessionStorage.removeItem('session');
    this.router.navigateByUrl('/login').then(() => {
      window.location.reload();
    });
  }

  replaceLocation(nextroute: any) {
    this.router.navigateByUrl(`login?next=${nextroute}`);
  }

  parseDate(date: Date | string, format: string = 'yyyyMMdd') {
    return formatDate(new Date(date), format, 'en-US').toString();
  }

  parseDateToShowInEdit(date: string) {
    // 20210729
    if (date != undefined) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);
      const formattedDate = month + '/' + day + '/' + year;
      const newDate = new Date(formattedDate);

      return newDate;
    } else {
      return '';
    }
  }

  encrypt(value: string) {
    if (value) {
      var key = CryptoJS.enc.Utf8.parse(this.secretKey);
      var iv = CryptoJS.enc.Utf8.parse(this.secretKey);
      var encrypted = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(value.toString()),
        key,
        {
          keySize: 128 / 8,
          iv: iv,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7,
        }
      );

      return encrypted.toString();
    } else {
      return '';
    }
  }

  decrypt(value: string) {
    if (value) {
      var key = CryptoJS.enc.Utf8.parse(this.secretKey);
      var iv = CryptoJS.enc.Utf8.parse(this.secretKey);
      var decrypted = CryptoJS.AES.decrypt(value, key, {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });

      return decrypted.toString(CryptoJS.enc.Utf8);
    } else {
      return '';
    }
  }

  reload() {
    window.location.reload();
  }

  setCookie(key: string, value: string) {
    const expiredDate = new Date();
    const encrypted_value = this.encrypt(value);
    const encrypted_key = this.encrypt(key);
    expiredDate.setTime(expiredDate.getTime() + 365 * 24 * 60 * 60 * 1000);
    this.cookieService.set(encrypted_key, encrypted_value, {
      expires: expiredDate,
      sameSite: 'Lax',
      path: '/',
    });
  }
  isBase64(str: string) {
    try {
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  validateMail(mail: string) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        mail
      )
    ) {
      return true;
    }
    return false;
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  checkUserId(userid: string) {
    const checkmail = this.validateMail(userid);
    if (!checkmail && !userid.includes('@')) {
      if (userid.startsWith('09')) {
        userid = '+959' + userid.slice(2, userid.length);
      } else if (userid.startsWith('+959')) {
        userid = userid;
      } else {
        userid = '+959' + userid;
      }
    } else {
      userid = userid.toLowerCase();
    }

    return userid;
  }


  convertFileToBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);  // Resolve the promise with the Base64 string
      };
      reader.onerror = (error) => {
        reject('Error converting file to Base64: ' + error);  // Reject the promise on error
      };
      reader.readAsDataURL(file);  // Start reading the file as Base64 string
    });
  }
  

  fileUploadToS3(file: File, name: string) {
    return new Promise((resolve) => {
      const contentType = file.type;
      const bucket = new S3({
        accessKeyId: this.acc_key,
        secretAccessKey: this.sec_key,
        region: this.region,
      });
      const params = {
        Bucket: 'elearningbucket',
        Key: 'channel/' + name,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType,
      };
      bucket.upload(params, function (err: any, data: any) {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          resolve(false);
        } else {
          console.log('Successfully uploaded file.', data);
          resolve(true);
        }
      });
    });
  }

  /**
   *
   * @param file
   * @param name
   * @param path
   * @returns
   */
  fileUploadToS3WithPath(file: File, name: string, path: string) {
    return new Promise((resolve) => {
      const contentType = file.type;
      const bucket = new S3({
        accessKeyId: this.acc_key,
        secretAccessKey: this.sec_key,
        region: this.region,
      });
      const params = {
        Bucket: 'elearningbucket',
        Key: path + '/' + name,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType,
      };
      bucket.upload(params, function (err: any, data: any) {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          resolve(false);
        } else {
          console.log('Successfully uploaded file.', data);
          resolve(true);
        }
      });
    });
  }

  fileUploadToS3WithPathForImageAndProgress(
    file: File,
    name: string,
    path: string
  ) {
    const contentType = file.type;
    const bucket = new S3({
      accessKeyId: this.acc_key,
      secretAccessKey: this.sec_key,
      region: this.region,
    });
    const params = {
      Bucket: 'elearningbucket',
      Key: path + '/' + name,
      Body: file,
      ACL: 'public-read',
      ContentType: contentType,
    };
    return bucket.upload(params, function (err: any, data: any) {
      if (err) {
        console.log('There was an error uploading your file: ', err);
      } else {
        console.log('Successfully uploaded file.', data);
      }
    });
  }

  fileUploadToS3WithPathForImage(file: File, name: string, path: string) {

    return new Promise<void>((resolve, reject) => {
      const contentType = file.type;
      const bucket = new S3({
        accessKeyId: this.acc_key,
        secretAccessKey: this.sec_key,
        region: this.region,
      });
      const params = {
        Bucket: 'elearningbucket',
        Key: path + '/' + name,
        Body: file,
        ACL: 'public-read',
        ContentType: contentType,
      };
      bucket.upload(params, function (err: any, data: any) {
        if (err) {
          console.log('There was an error uploading your file: ', err);
          reject();
        } else {
          console.log('Successfully uploaded file.', data);
          resolve();
        }
      });
    });
  }

  //Files Upload to AWS S3 with Loop with path
  multipleUploadToAWS(filelist: any, folder: string) {
    return new Promise((resolve) => {

      resolve(true);
      const bucket = new S3({
        accessKeyId: this.acc_key,
        secretAccessKey: this.sec_key,
        region: this.region,
      });
      for (let i = 0; i < filelist.length; i++) {
        console.log('File>>> ' + filelist[i].filename);

        const contentType = filelist[i].file.type;
        const params = {
          Bucket: 'elearningbucket',
          Key: folder + '/' + filelist[i].filename,
          Body: filelist[i].file,
          ACL: 'public-read',
          ContentType: contentType,
        };
        bucket.upload(params, function (err: any, data: any) {
          if (err) {
            console.log('There was an error uploading your file: ', err);
            resolve(false);
          } else {
            console.log('Successfully uploaded file.', data);
            if (filelist.length - 1 == i) {
              console.log('FINAL>>>');
              resolve(true);
              //   // return true;
            }
          }
        });
      }
    });
  }

  formatDBToShow(date: string) {
    // 20210729
    if (date != undefined) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);
      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  }

  formatDBToShowInEdit(date: string, spliter?: string) {
    // 20210729
    if (date != undefined) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);

      if (spliter == undefined) {
        return year + '-' + month + '-' + day;
      } else {
        return year + spliter + month + spliter + day;
      }
    } else {
      return '';
    }
  }

  f24to12(time: String) {
    if (time != undefined || time != '') {
      var temp = time.split(':');
      const hours = parseInt(temp[0]);
      const minutes = temp[1];
      const period = hours >= 12 ? 'PM' : 'AM';
      const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
      const formattedMinutes = minutes.padStart(2, '0');
      const formattedTime = `${formattedHours}:${formattedMinutes} ${period}`;
      return formattedTime;
    }
    return '';
  }

  f12to24(time12hr: string) {
    if (time12hr != undefined && time12hr != '') {

        const [time, period] = time12hr.split(' ');
        const [hours, minutes] = time.split(':');

        let hours24 = parseInt(hours);

        if (period === 'PM' && hours24 !== 12) {
          hours24 += 12;
        } else if (period === 'AM' && hours24 === 12) {
          hours24 = 0;
        }

        const formattedHours24 = hours24.toString().padStart(2, '0');
        const formattedMinutes = minutes.padStart(2, '0');

        return `${formattedHours24}:${formattedMinutes}`;
    }
    return '';
  }

  formatDate(date: string) {
    // 2021-07-20
    if (date != undefined) {
      return date.split('-').join('');
    } else {
      return '';
    }
  }

  formatDateTwo(date: string) {
    // 2021-07-20
    if (date != undefined) {
      var t = date.split('/').reverse().join('');
      return t
    } else {
      return '';
    }
  }

  formatTimeAmpm(time: String) {
    if (time.toUpperCase() == 'AM' || time.toUpperCase() == 'PM') {
      return time;
    }
    const timeParts = time.split(':');
    const hours24 = parseInt(timeParts[0], 10);
    const minutes = parseInt(timeParts[1], 10);

    const date = new Date();
    date.setHours(hours24, minutes);

    const timeString12 = date.toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
    });
    return timeString12;
  }

  exportExcel(data: any, name: string) {
    /* generate a worksheet */
    var ws = XLSX.utils.json_to_sheet(data);

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* write workbook and force a download */
    XLSX.writeFile(wb, name);
  }

  exportCSV(data: any, name: string) {
    /* generate a worksheet */
    var ws = XLSX.utils.json_to_sheet(data);

    /* add to workbook */
    var wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    console.log(wb.Sheets);

    // Change to csv
    const worksheet = wb.Sheets['Sheet1'];
    var mycsv = XLSX.utils.sheet_to_csv(worksheet);

    this.saveAsFile(mycsv, name);
  }

  saveAsFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer]);
    FileSaver.saveAs(data, fileName);
  }

  async compressImage(imageFile: File, maxSize?: number) {
    return new Promise(async (resolve) => {
      const options = {
        maxSizeMB: maxSize ? maxSize : 0.3,
        useWebWorker: true,
        // maxWidthOrHeight: 1024,
      };
      try {
        const compressedFile = await imageCompression(imageFile, options);
        resolve(compressedFile);
      } catch (error) {
        resolve(false);
      }
    });
  }

  formatDateForDiscussionDatePipe(date: string) {
    // 20210729
    if (date != undefined) {
      const year = date.substring(0, 4);
      const month = date.substring(4, 6);
      const day = date.substring(6, 8);
      return year + '/' + month + '/' + day;
    } else {
      return '';
    }
  }

  isRootAdmin() {
    return this.user.userrole == '100';
  }

  isChlAdmin() {
    return this.adminChannels.length > 0;
  }

  isSupervisor() {
    return this.supervisorChannels.length > 0;
  }

  isHRAdmin(){
    return this.hradminChannels.length > 0;
  }


  getFileName(file: any) {
    var name = file.name.trim();
    var splitName = name.split('.');
    var finalName = '';
    for (let i = 0; i < splitName.length; i++) {
      if (i == splitName.length - 1) {
        finalName = finalName + '_' + this.generateID() + '.' + splitName[i];
      } else {
        finalName = finalName + splitName[i];
      }
    }
    return finalName;
  }
  generateID() {
    var now = new Date(Date.now());
    var hours: any = now.getHours();
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours.toString().length == 1 ? 0 + '' + hours : hours;
    hours = (Number(hours) + 12).toString();
    var minutes: any = now.getMinutes();
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var second: any = now.getSeconds();
    second = second < 10 ? '0' + second : second;

    var time = hours + '' + minutes + '' + second;
    return this.getCurrentDateToDB() + time;
  }

  getCurrentDateToDB() {
    var now = new Date(Date.now());
    var year = now.getFullYear();
    var month: any = now.getMonth() + 1;
    month = month.toString().length == 1 ? 0 + '' + month : month;
    var day: any = now.getDate();
    day = day.toString().length == 1 ? 0 + '' + day : day;

    var currentdate = year + '' + month + '' + day;

    return currentdate;
  }

  getCurrentTimeToDB2() {
    var now = new Date(Date.now());
    var hours: any = now.getHours();
    var minutes: any = now.getMinutes();
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours.toString().length == 1 ? 0 + '' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var second: any = now.getSeconds();
    second = second < 10 ? '0' + second : second;
    var currentdate = hours + minutes + second;
    return currentdate;
  }

  base64ToFile(data: any, filename: any) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  handleImgError(ev: any) {
    let source = ev.srcElement;
    let imgSrc = this.fileurl + 'essential/defaultimage.jpg';
    source.src = imgSrc;
  }
  scrollTo(id: string) {
    let element = document.getElementById(id);
    element?.scrollIntoView();
  }
}
