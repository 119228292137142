import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { CookieService } from 'ngx-cookie-service';
import {
  NgxQrcodeElementTypes,
  NgxQrcodeErrorCorrectionLevels,
} from '@techiediaries/ngx-qrcode';
import { SEOService } from '../../shared/services/seo.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.MEDIUM;

  qrLoading: boolean = true;
  showRefresh: boolean = false;

  createdDate = '';
  formatTime = '';
  value: any = '';

  countGetQr = 1;

  getQrInterval: any;
  checkQrInterval: any;

  dateFormat = 'yyyyMMddhhmmss';
  secretkey = '@s3cr#CTKeyF0rQRL)g1nT0ken';

  form = {
    appid: 'kunyek',
    uniquekey: '',
  };

  signupForm = this.formBuider.group({
    userid: ['', Validators.required],
    password: ['', Validators.required],
    isrememberme: false,
  });

  signupFormSubmitted: boolean = false;

  width: number = 1024;
  isLoading: boolean = false;
  retry: boolean = false;
  isQrLogin: boolean = true;
  autoSignInPage: boolean = true;

  isStillSigningIn: boolean = false;
  fieldTextType: boolean = false;

  constructor(
    private formBuider: FormBuilder,
    private router: Router,
    public allinoneService: AllInOneService,
    private courseService: CourseService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private _seoService: SEOService
  ) {
    const kunyek_userid = this.route.snapshot.queryParamMap.get('userid') || '';

    if (
      this.allinoneService.getAtoken() &&
      this.allinoneService.getKunyekUserId() ==
        this.allinoneService.checkUserId(kunyek_userid)
    ) {
      this.router.navigateByUrl('/home');
    }
  }
  ngOnDestroy(): void {
    clearInterval(this.getQrInterval);
    clearInterval(this.checkQrInterval);
  }

  ngOnInit(): void {
    this._seoService.updateTitle('- Sign In');
    // this.qrLogin();
    this.width = window.screen.width;
    // if (this.width < 1025) {
    //   this.user();
    // }
    // console.log('show qr');
    // console.log(this.allinoneService.showQrSignin);

    if (this.allinoneService.showQrSignin) {
      this.qrLogin();
    } else {
      this.phLogin();
    }
    let yuecoDomainList = ["yueco.knowledgehub.edu.mm", "yueco1.knowledgehub.edu.mm", "lms.yueco.edu.mm"]
    if (this.allinoneService.showQrSignin && 
      yuecoDomainList.includes(this.allinoneService.currentDomain)
    ) {
      (this.isQrLogin = false), (this.autoSignInPage = false);
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  onRememberMeChange(e: any) {
    this.signupForm.value.isrememberme = e.target.checked;
  }
  user() {
    this.isLoading = true;
    const appid = this.route.snapshot.queryParamMap.get('appid') || '';
    const kunyek_userid = this.route.snapshot.queryParamMap.get('userid') || '';
    const domain = this.route.snapshot.queryParamMap.get('domain') || '';
    const atoken = this.route.snapshot.queryParamMap.get('atoken');
    const username = this.route.snapshot.queryParamMap.get('username') || '';
    const channelid = this.route.snapshot.queryParamMap.get('channelid') || '';
    this.allinoneService.channelid = channelid;
    sessionStorage.setItem('kunyekusername', username);

    if (appid == '026' && kunyek_userid && domain && !atoken) {
      // Here Call API
      //if success
      //Check user, create new if not exist
      const data = {
        appid: appid,
        userid: this.allinoneService.checkUserId(kunyek_userid),
        domain: domain,
      };
      this.courseService.hcmLogin(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.allinoneService.setCookie('channelid', channelid);
            this.allinoneService.setCookie('appid', res.appid || '');

            this.allinoneService.setCookie(
              'kunyek_userid',
              this.allinoneService.checkUserId(res.userid) || ''
            );

            this.allinoneService.setCookie('atoken', res.atoken || '');
            this.courseService.checkUser().subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                  if (res.status == 0) {
                    //user does not exits
                    this.courseService.createUser(username).subscribe(
                      (res: any) => {
                        if (res.returncode == '300') {
                          this.isLoading = false;
                          this.allinoneService.setCookie(
                            'userid',
                            res.data.usersyskey
                          );
                          this.router.navigateByUrl('/home');
                        } else {
                          this.isLoading = false;
                          this.retry = true;

                          if (res.returncode == '210') {
                            setTimeout(() => {
                              this.router.navigateByUrl('login');
                              this.allinoneService.logout();
                            }, 2000);
                          } else {
                            this.messageService.openSnackBar(
                              res.status,
                              'warn'
                            );
                          }
                        }
                      },
                      (err) => {
                        this.isLoading = false;
                        this.retry = true;
                      }
                    );
                  } else if (res.status == 1) {
                    //user exits
                    this.isLoading = false;

                    this.allinoneService.setCookie('userid', res.usersyskey);
                    this.router.navigateByUrl('/home');
                  }
                } else {
                  this.isLoading = false;
                  this.retry = true;
                  if (res.returncode == '210') {
                    setTimeout(() => {
                      this.router.navigateByUrl('login');
                      this.allinoneService.logout();
                    }, 2000);
                  } else {
                    this.messageService.openSnackBar(
                      res.status || res.message || 'Server Error',
                      'warn'
                    );
                  }
                }
              },
              (err) => {
                this.isLoading = false;
                this.retry = true;
              }
            );
          } else {
            this.messageService.openSnackBar('Unauthorized Access', 'warn');
            this.width = 1025;
          }
        },
        (err) => {
          this.isLoading = false;
          this.retry = true;
        }
      );
    } else if (appid && kunyek_userid && atoken) {
      this.allinoneService.setCookie('channelid', channelid);
      this.allinoneService.setCookie('appid', appid || '');

      this.allinoneService.setCookie(
        'kunyek_userid',
        this.allinoneService.checkUserId(kunyek_userid) || ''
      );

      this.allinoneService.setCookie('atoken', atoken || '');

      //Check Token
      this.courseService.checkToken().subscribe(
        (res: any) => {
          if (res.returncode == '1' || res.returncode == '3') {
            this.messageService.openSnackBar('Unauthorized Access', 'warn');
            this.width = 1025;
          } else {
            //user
            // kunyek user to create a new one if not in elearning. [Elearning Server]
            this.courseService.checkUser().subscribe(
              (res: any) => {
                if (res.returncode == '300') {
                  if (res.status == 0) {
                    //user does not exits
                    this.courseService.createUser(username).subscribe(
                      (res: any) => {
                        if (res.returncode == '300') {
                          this.isLoading = false;
                          this.allinoneService.setCookie(
                            'userid',
                            res.data.usersyskey
                          );
                          this.router.navigateByUrl('/home');
                        } else {
                          this.isLoading = false;
                          this.retry = true;

                          if (res.returncode == '210') {
                            setTimeout(() => {
                              this.router.navigateByUrl('login');
                              this.allinoneService.logout();
                            }, 2000);
                          } else {
                            this.messageService.openSnackBar(
                              res.status,
                              'warn'
                            );
                          }
                        }
                      },
                      (err) => {
                        this.isLoading = false;
                        this.retry = true;
                      }
                    );
                  } else if (res.status == 1) {
                    //user exits
                    this.isLoading = false;

                    this.allinoneService.setCookie('userid', res.usersyskey);
                    this.router.navigateByUrl('/home');
                  }
                } else {
                  this.isLoading = false;
                  this.retry = true;
                  if (res.returncode == '210') {
                    setTimeout(() => {
                      this.router.navigateByUrl('login');
                      this.allinoneService.logout();
                    }, 2000);
                  } else {
                    this.messageService.openSnackBar(
                      res.status || res.message || 'Server Error',
                      'warn'
                    );
                  }
                }
              },
              (err) => {
                this.isLoading = false;
                this.retry = true;
              }
            );
          }
        },
        (err) => {
          this.isLoading = false;
          this.retry = true;
        }
      );
    } else {
      if (appid == '') {
        this.messageService.openSnackBar('App ID is missing', 'warn');
      } else if (kunyek_userid == '') {
        this.messageService.openSnackBar('User ID is missing', 'warn');
      } else if (atoken == '') {
        this.messageService.openSnackBar('Token is missing', 'warn');
      }
      this.courseService
        .addServerLog(this.router.url)
        .subscribe((res: any) => {});
      this.width = 1025;
    }
  }

  get signupFormControl() {
    return this.signupForm.controls;
  }

  signUp() {
    this.signupFormSubmitted = true;

    if (this.signupForm.invalid) {
      return;
    } else {
      this.isStillSigningIn = true;
      sessionStorage.setItem(
        'rememberme',
        this.signupForm.value.isrememberme ? 'TRUE' : 'FALSE'
      );
      this.allinoneService.setCookie(
        'kunyek_userid',
        this.allinoneService.checkUserId(this.signupForm.value.userid)
      );

      this.courseService.checkUser().subscribe((c_res: any) => {
        if (c_res.returncode == '300') {
          if (c_res.status == 0) {
            //user does not exits
            this.messageService.openSnackBar(
              'User is not found. create an account',
              'warn'
            );
            this.isStillSigningIn = false;
          } else {
            this.courseService
              .signIn(
                this.allinoneService.checkUserId(this.signupForm.value.userid),
                this.signupForm.value.password
              )
              .subscribe(
                async (res: any) => {
                  if (res.returncode == '310') {
                    this.allinoneService.setCookie('username', c_res.username);
                    this.allinoneService.setCookie('atoken', res.atoken);
                    sessionStorage.setItem('session', res.session);
                    sessionStorage.setItem('existsuser', res.type);
                    sessionStorage.setItem('kunyekusername', res.username);
                    this.allinoneService.setCookie('userid', c_res.usersyskey);
                    const await_enroll_course = await this.enrollCourse();
                    this.goToPage();
                    // this.isStillSigningIn = false;
                  } else {
                    this.allinoneService.clearUserid();
                    // if (res.returncode == "300") {
                    this.messageService.openSnackBar(
                      'Check your (email or phone number) and password or create an account. ',
                      'warn'
                    );
                    // }
                    // else {
                    //   this.messageService.openSnackBar(res.message, 'warn');
                    // }
                    this.isStillSigningIn = false;
                  }
                },
                (err) => {
                  this.allinoneService.clearUserid();
                  this.isStillSigningIn = false;
                }
              );
          }
        } else {
          this.isStillSigningIn = false;
          this.messageService.openSnackBar(
            c_res.message || c_res.status || 'Server Error',
            'warn'
          );
        }
      });
    }
  }

  async forgotPassword() {
    this.router.navigateByUrl('student/forgot-password/1');
  }

  goToPage() {
    const nex_route: any = this.route.snapshot.queryParamMap.get('next');
    var route = '';

    if (nex_route) {
      let myroute = nex_route.split(',');
      myroute.map((item: string, index: number) => {
        if (index == 0) {
          route = encodeURIComponent(item);
        } else {
          route = route + '/' + encodeURIComponent(item);
        }
      });
    } else {
      route = '/home';
    }

    // this.router.navigateByUrl(route).then(() => {
    // window.location.reload();
    // });
    // this.router
    //   .navigateByUrl(route, { skipLocationChange: true })
    //   .then(() => window.location.reload());
    // console.log('here');
    window.location.replace(route);
  }

  goToSignUP() {
    const next_route: any = this.route.snapshot.queryParamMap.get('next');
    if (!next_route) {
      this.router.navigateByUrl(`signup`);
    } else {
      this.router.navigateByUrl(`signup?next=${next_route}`);
    }
  }

  qrLogin() {
    this.isQrLogin = true;
    this.getQR();
    this.checkQrInterval = setInterval(() => {
      this.checkQr();
      console.log('checking qr');
    }, 5000);
  }

  phLogin() {
    clearInterval(this.checkQrInterval);
    clearInterval(this.getQrInterval);
    this.isQrLogin = false;
  }

  getQR() {
    console.log('getting qr');

    this.qrLoading = true;
    this.showRefresh = false;

    this.courseService.requestQR(this.value).subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.value = res.qr;

          this.qrLoading = false;

          if (this.countGetQr == 1) {
            this.getQrInterval = setInterval(() => {
              if (this.countGetQr == 2) {
                clearInterval(this.getQrInterval);
                this.phLogin();
              } else {
                this.getQR();
              }
              this.countGetQr++;
            }, 1000 * 60 * 2 + 50);
          }
        } else {
          this.qrLoading = false;
          this.showRefresh = true;
        }
      },
      (err: any) => {
        this.qrLoading = false;
        this.showRefresh = true;
      }
    );
  }

  checkQr() {
    this.courseService.checkQR(this.value).subscribe((res: any) => {
      if (res.returncode == '300') {
        this.allinoneService.setCookie('kunyek_userid', res.userid);
        this.allinoneService.setCookie('atoken', res.atoken);
        this.createUser(res.username);
        clearInterval(this.checkQrInterval);
        clearInterval(this.getQrInterval);
      }
    });
  }

  async createUser(username: string) {
    this.courseService.checkUser().subscribe(
      async (res: any): Promise<any> => {
        if (res.returncode == '300') {
          //user does not exits
          if (res.status == 0) {
            this.courseService.createUser(username).subscribe(
              async (learn_res: any) => {
                if (learn_res.returncode == '300') {
                  this.allinoneService.setCookie('username', username);
                  this.allinoneService.setCookie(
                    'userid',
                    learn_res.data.usersyskey
                  );
                  const await_enroll_course = await this.enrollCourse();
                  this.goToPage();
                } else {
                  this.messageService.openSnackBar(res.status, 'warn');
                }
              },
              (err) => {
                this.getQR();
              }
            );
          } else {
            this.allinoneService.setCookie('username', res.username);
            this.allinoneService.setCookie('userid', res.usersyskey);
            const await_enroll_course = await this.enrollCourse();
            this.goToPage();
          }
        } else {
          this.messageService.openSnackBar(
            res.status || res.message || 'Server Error',
            'warn'
          );
          this.countGetQr = 1;
          this.getQR();
          this.checkQrInterval = setInterval(() => {
            this.checkQr();
            console.log('checking qr');
          }, 5000);
        }
      },
      (err) => {}
    );
  }

  goToPage2() {
    setTimeout(() => {
      const nex_route: any = this.route.snapshot.queryParamMap.get('next');
      var route = '';

      if (nex_route) {
        route = nex_route.toString().replaceAll(',', '/');
        // this.router.navigateByUrl(route);
      } else {
        route = '/home';
        // this.router.navigateByUrl(route);
      }
      window.location.replace(route);

      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    }, 3000);
  }

  enrollCourse() {
    return new Promise((resolve) => {
      let temp = sessionStorage.getItem('course');
      const course = temp ? JSON.parse(temp) : { courseid: '' };
      if (course.courseid) {
        this.courseService
          .enrollCourse(course.courseid, course.completedunits)
          .subscribe(
            (res: any) => {
              sessionStorage.removeItem('course');
              resolve(true);
            },
            (err) => {
              sessionStorage.removeItem('course');
              resolve(false);
            }
          );
      } else {
        sessionStorage.removeItem('course');
        resolve(true);
      }
    });
  }
}
