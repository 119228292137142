<div class="loading-center" *ngIf="isLoading">
    <!-- <mat-spinner [strokeWidth]="10" style="zoom: 0.4;"></mat-spinner> -->
    <mat-spinner [strokeWidth]="3" [diameter]="33"></mat-spinner>
</div>

<div *ngIf="!isLoading && !isError">
    <nav class="navbar navbar-expand-lg  px-3  navbar-light d-flex">
        <div class="container-fluid">
            <div class=" d-flex align-items-center">
                <a href="/">
                    <img src="assets/images/khub.png" alt="" class="navbar-brand-logo">
                </a>
                <div class="vertical-divider"></div>
                <!-- <span class="course-title">{{course.coursename}}</span> -->
                <app-breadcrumb [s_breadcrumb]="'My Courses'" [t_breadcrumb]="course.coursename"></app-breadcrumb>
            </div>

            <!-- <div class="unit-progress">
                <div class="progress">
                    <div class="progress-bar progress-bar-striped" role="progressbar" style="width: 25%" aria-valuenow="25" aria-valuemin="0"
                        aria-valuemax="100"></div>
                </div>
                <div class="progress-per">
                    <strong>25%</strong> COMPLETE
                </div>
            </div> -->
        </div>
    </nav>




    <!-- <div class="course-title-mobile d-flex justify-content-between align-items-center d-sm-none px-2">
        <mat-icon (click)="navigateBack()">keyboard_backspace</mat-icon>
        <span class="course-title mx-2">{{course.coursename}}</span>
    </div> -->

    <div class="main-content d-flex " *ngIf="!isLoading">
        <div class="course-content w-25">
            <!-- <div class="d-flex justify-content-between align-items-center px-2 pr-5 py-3">
                <span class="course-title mx-2">Course Content</span>
                <button type="button" class="btn-close" aria-label="Close" (click)="closeCourseContent()"></button>
            </div> -->
            <div class="course-title-mobile d-flex justify-content-between align-items-center d-sm-none px-2">
                <span class="course-title mx-2">{{course.coursename}}</span>
            </div>

            <div class="tab-section d-flex px-2 py-2">
                <div matRipple [ngClass]="{'tab-active': tab_active == 1}" (click)="tabClick(1)">
                    Content
                </div>
                <div matRipple [ngClass]="{'tab-active': tab_active == 2}" (click)="tabClick(2)">
                    About
                </div>
            </div>


            <!-- course content -->
            <div *ngIf="tab_active == 1" class=" custom-accordion  accordion">
                <div class="accordion-item" *ngFor="let section of course.sections;let s = index;">
                    <h2 class="accordion-header" [id]="'heading' + s">
                        <button class="custom-accordion-button accordion-button sectionname collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse' + s" aria-expanded="true" [attr.aria-controls]="'collapse' + s">
                            <!-- <strong class="mx-1"> {{ s+ 1+ ' - '}}</strong> -->
                            {{ section.sectionname}}
                        </button>
                    </h2>

                    <div [id]="'collapse' + s" class="accordion-collapse collapse" [ngClass]="section.show">
                        <div class="section-accordion-body">
                            <div class="accordion-item" *ngFor="let subsection of section.subsections;let ss = index;">
                                <h2 class="accordion-header" id="headingSubsectionOne">
                                    <button class="custom-accordion-button custom-sub-accordion-button accordion-button subsectioname collapsed" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseSubsection' + s +'-' + ss" aria-expanded="true" aria-controls="collapseOne">
                                        <!-- <strong class="mx-1"
                                            style="letter-spacing: 2px;">{{s+ 1 + '.' + (ss + 1 )+ ' - '}}</strong> -->
                                        {{subsection.subsectionname}}
                                    </button>
                                </h2>

                                <div [id]="'collapseSubsection' + s +'-' + ss" class="accordion-collapse collapse" [ngClass]="section.show" aria-labelledby="headingSubsectionOne">
                                    <div class="accordion-body">
                                        <ul>
                                            <li *ngFor="let unit of subsection.units;let su = index;" routerLinkActive="active">

                                                <mat-checkbox class="example-margin" color="primary" [(ngModel)]="unit.checked" (click)="updateCompletedUnits(unit)">
                                                </mat-checkbox>
                                                <div class="mx-2 unit" [routerLink]="['/courses/preview/'+courseid+'/lectures/'+unit.unitid]" (click)="viewLecture(unit)">
                                                    <div>{{unit.unitname}} </div>
                                                    <div>
                                                        <i *ngIf="unit.contenttype == 1" class="fa fa-play-circle-o document" aria-hidden="true">
                                                            <span class="mx-1"> Video</span>
                                                        </i>
                                                        <i *ngIf="unit.contenttype == 2" class="fa fa-file-o document" aria-hidden="true">
                                                            <span class="mx-1"> File </span>
                                                        </i>
                                                        <i *ngIf="unit.contenttype == 3" class="fa fa-file-text-o document" aria-hidden="true">
                                                            <span class="mx-1"> Article </span>
                                                        </i>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div *ngIf="tab_active == 2">

                <div class=" p-3 pt-lg-2 p-sm-0 pt-sm-2 mb-2">
                    <div class="container">
                        <h3 style="font-weight: 700;">About this course</h3>

                        <div class="show-more-container" [class.show]="show">
                            <p class="fs-6" [innerHTML]="course.description">
                            </p>
                        </div>

                        <button mat-button color="primary" class="d-flex justify-end" style="margin-left: -1rem;" (click)="show = !show">
                            <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down"
                                    aria-hidden="true"></i></span>
                            <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up"
                                    aria-hidden="true"></i></span>
                        </button>
                    </div>

                </div>

                <div class=" p-3  p-sm-0 mb-2">
                    <div class="container">
                        <h3 style="font-weight: 700;">Instructor</h3>


                        <div class="instructor my-2" *ngIf="instructor">
                            <div class="instructor-default-img" *ngIf="instructor.profileimage == ''">
                                {{instructor.username.charAt(0)}}
                            </div>
                            <img width="40" height="40" class="rounded-circle" *ngIf="instructor.profileimage != ''" [src]="allinOneService.imgurl + instructor.profileimage" alt="">
                            <span class="instructor-name fw-bold mx-2">{{instructor.username}}</span>
                        </div>
                    </div>

                </div>
            </div>


        </div>


        <div class="learn-content w-75">
            <div class="web-view  p-3">
                <!-- <i class="fa fa-bars" aria-hidden="true" (click)="openCourseContent()"></i> -->
                <div class="d-flex align-items-baseline mb-4 mt-2 d-none d-sm-flex">
                    <i *ngIf="lecture.contenttype == 1" class="fa fa-play-circle-o document" aria-hidden="true"></i>
                    <i *ngIf="lecture.contenttype == 2" class="fa fa-file-o document" aria-hidden="true"></i>
                    <i *ngIf="lecture.contenttype == 3" class="fa fa-file-text-o document" aria-hidden="true"></i>
                    <span class="course-title mx-2"> {{lecture.unitname}} </span>
                </div>


                <div class="video-responsive" *ngIf="lecture.contenttype == 1">
                    <iframe width="420" height="315" [src]="lecture.content | safe" scrolling="no" frameborder="0" allowfullscreen></iframe>

                    <!-- <plyr [plyrSources]="videoSources" (plyrInit)="player = $event" (plyrEnded)="plyrEnded($event)"
                        (plyrError)="plyrError($event)"></plyr> -->



                </div>

                <!-- https://youtu.be/vESXsXi7JcQ -->

                <div *ngIf="lecture.contenttype != 1" class="learn-content-mobile">
                    <div class="opendocument">
                        <img [src]="allinOneService.imgurl + course.courseimage" (error)="handleImgError($event)" width="240" height="140" alt="">

                        <div class="course-learn-overlay">
                            <strong class="course-title">{{lecture.unitname}}</strong>

                            <button mat-flat-button color="primary" class="my-3" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop">Open</button>
                        </div>
                    </div>
                    <!-- <ngx-doc-viewer *ngIf="!isDocumentLoading" class="doc" queryParams="hl=my"
                    [url]="allinOneService.docurl + lecture.content" viewer="google" style="width:100%;height:calc(100dvh - 75px);">
                </ngx-doc-viewer> -->
                </div>

                <div *ngIf="lecture.contenttype == 2" class="learn-content-web">
                    <div *ngIf="isDocumentLoading" id="progressBar">
                        <ngx-skeleton-loader [theme]="{ height: '600px',width: '100%'}">
                        </ngx-skeleton-loader>
                    </div>
                    <ngx-doc-viewer id="doc" queryParams="hl=my" [url]="allinOneService.docurl + lecture.content" viewer="google" style="width:100%;height:calc(100dvh - 75px);">
                    </ngx-doc-viewer>
                </div>

                <div *ngIf="lecture.contenttype == 3" class="pt-1 learn-content-web">
                    <p [innerHTML]="lecture.content"></p>
                </div>
            </div>


            <div class="web-view-about  p-3">
                <div class=" p-3 pt-lg-4 p-sm-0 pt-sm-2 mb-2">
                    <div class="container">
                        <h3 style="font-weight: 700;">About this course</h3>

                        <div class="show-more-container" [class.show]="show">
                            <p class="fs-6 my-2" [innerHTML]="course.description">
                            </p>
                        </div>

                        <button mat-button color="primary" class="d-flex justify-end" style="margin-left: -1rem;" (click)="show = !show">
                            <span *ngIf="!show" class="fw-bold">Show more <i class="fa fa-angle-down"
                                    aria-hidden="true"></i></span>
                            <span *ngIf="show" lass="fw-bold">Show less <i class="fa fa-angle-up"
                                    aria-hidden="true"></i></span>
                        </button>
                    </div>

                </div>

                <section class=" p-3  p-sm-0 mb-2">
                    <div class="container">
                        <h3 style="font-weight: 700;">Instructor</h3>

                        <div class="instructor my-2" *ngIf="instructor">
                            <div class="instructor-default-img" *ngIf="instructor.profileimage == ''">
                                {{instructor.username.charAt(0)}}
                            </div>
                            <img width="40" height="40" class="rounded-circle" *ngIf="instructor.profileimage != ''" [src]="allinOneService.imgurl + instructor.profileimage" alt="">
                            <span class="instructor-name fw-bold mx-2">{{instructor.username}}</span>
                        </div>
                    </div>

                </section>
            </div>

        </div>
    </div>



    <div class="offcanvas offcanvas-top" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
        <div class="offcanvas-header">
            <button mat-flat-button data-bs-dismiss="offcanvas" color="primary" style="    position: absolute;
            top: 10px;
            right: 15px;
            z-index: 3;">Close</button>
            <!-- <nav class="breadcrumb" aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item"><a href="/courses">My Courses</a></li>
                    <li class="breadcrumb-item active" aria-current="page"> {{lecture.unitname}}</li>
                </ol>
            </nav> -->
        </div>
        <div class="offcanvas-body">

            <div *ngIf="lecture.contenttype == 2" class="d-flex align-items-center justify-content-between offcanvas-mobile" style=" z-index: 2;
            position: absolute;
            left: 0;
            right: 0;
            top: 53px;background-color: #fff;">
                <!-- <div class="loading-center" *ngIf="lecture.contenttype == 2 && isDocumentLoading">
                    <mat-spinner [strokeWidth]="10" style="zoom: 0.4;"></mat-spinner>
                </div> -->
                <ngx-doc-viewer *ngIf="lecture.contenttype == 2" id="doc" queryParams="hl=my" [url]="allinOneService.docurl + lecture.content" viewer="google" style="width:100%;height:calc(100dvh - 75px);" (loaded)="documentLoaded()">
                </ngx-doc-viewer>
            </div>

            <span *ngIf="lecture.contenttype == 2" style="    position: absolute;
            top: 50%;
            left: 40%;">Loading...</span>



            <div class="d-flex" *ngIf="lecture.contenttype == 3">
                <div class="pt-1">
                    <p [innerHTML]="lecture.content"></p>
                </div>
            </div>

            <button mat-flat-button data-bs-dismiss="offcanvas" color="primary" style="    position: absolute;
            top: 10px;
            right: 15px;
            z-index: 3;">Close</button>
        </div>
    </div>
</div>


<div class="check-error" *ngIf="isError && !isLoading">
    <h3 style="margin: 0;">Something went wrong!, please reload</h3><br>
    <button type="button" (click)="reload()" class="btn btn-outline-secondary">
        Reload
    </button>
</div>