import {
  Component,
  OnInit,
  SimpleChanges,
  Input,
  HostListener,
  ViewChild,
} from '@angular/core';
import { AllInOneService } from '../../shared/services/all-in-one.service';
import { CourseService } from '../../shared/services/course.service';
import { MessageService } from '../../shared/services/message.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormStyle } from '@angular/common';
import { User } from '../../shared/models/User';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
})
export class TopBarComponent implements OnInit {
  width: any;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.width = event.target.innerWidth;
    console.log(this.width);
    
  }

  // @Input() user!: User;

  // @ViewChild('testing1234') trigger!: MatMenuTrigger;

  @ViewChild('catmenutrigger', {read: MatMenuTrigger}) protected trigger! : MatMenuTrigger;

  authenticate: boolean = false;
  categories: any = [];
  channels: any = [];
  gettingCategory: boolean = false;
  gettingChannel: boolean = false;
  errMsg: string = '';
  searchTerm: string = '';
  isChannel: boolean = false;
  channelid: string = '';
  analyticIcon: string = `${this.allinOneService.fileurl}policyicons/analytic.png`;

  user!: User;
  constructor(
    public allinOneService: AllInOneService,
    public deviceService: DeviceDetectorService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private courseService: CourseService
  ) {
    this.searchTerm = this.route.snapshot.queryParamMap.get('q') || '';
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    // if (this.allinOneService.getAtoken()) {
    if (this.allinOneService.getAtoken() && this.allinOneService.user) {
      this.authenticate = true;
    }
  }

  ngOnInit(): void {
    this.width = window.innerWidth;
    this.user = this.allinOneService.user;
  }

  ngOnChanges() {
    // create header using child_id
    console.log(this.authenticate);
  }

  getCategoryListWithCount() {
    this.gettingCategory = true;
    this.courseService.getCategoryListWithCounts().subscribe(
      (res: any) => {
        if (res.returncode == '300') {
          this.categories = res.datalist;
          if (this.categories.length > 0) {
            this.allinOneService.setCookie(
              'categorieswithcounts',
              JSON.stringify(this.categories) || 'undefined'
            );
            this.allinOneService.categorieswithcounts = this.categories;
          }
        } else {
          this.errMsg = res.status;
          // this.messageService.openSnackBar(res.status, 'warn');

          if (res.returncode == '210') {
            setTimeout(() => {
              this.router.navigateByUrl('login');
              this.allinOneService.logout();
            }, 2000);
          }
        }
        this.gettingCategory = false;
      },
      (err) => {
        this.gettingCategory = false;
        this.errMsg = 'err';
      }
    );
  }

  getCategoryList() {
    this.courseService.getCategoryList().subscribe((res: any) => {
      if (res.returncode == '300') {
        // if (this.categories.length > 0) {
        this.allinOneService.setCookie(
          'categories',
          JSON.stringify(res.data) || 'undefined'
        );
        this.allinOneService.categories = res.data;
        // }
      }
    });
  }

  getChannelList() {
    this.gettingChannel = true;
    const searchuserid = this.allinOneService.getKunyekUserId();

    if (searchuserid != '') {
      this.isChannel = false;
      const data = {
        role: '0',
        searchuserid: searchuserid.toString(),
      };
      this.courseService.getMychannel(data).subscribe(
        (res: any) => {
          if (res.returncode == '300') {
            this.channels = res.datalist;
            if (
              this.channels.length == '1' &&
              this.channels[0].channelid == ''
            ) {
              this.isChannel = false;
              this.allinOneService.channelid = '';
              this.allinOneService.setCookie('channelid', '');
            } else {
              this.isChannel = true;
              var temp = this.channels.find((x: any) => {
                return x.channelid == this.allinOneService.channelid;
              });
              if (!temp) {
                this.allinOneService.channelid = '';
                this.allinOneService.setCookie('channelid', '');
              } else {
                this.allinOneService.selectedChannelName = temp.name;
                // this.allinOneService.privatecourse = temp.privatecourse;
              }
            }

            if (this.channels.length > 0) {
              this.allinOneService.setCookie(
                'channels',
                JSON.stringify(this.channels) || 'undefined'
              );
              this.allinOneService.channels = this.channels;
            }
            this.gettingChannel = false;
          } else {
            this.errMsg = res.status;
            this.gettingChannel = false;
            // this.messageService.openSnackBar(res.status, 'warn');

            if (res.returncode == '210') {
              setTimeout(() => {
                this.router.navigateByUrl('login');
                this.allinOneService.logout();
              }, 2000);
            }
          }
          this.gettingChannel = false;
        },
        (err) => {
          this.gettingChannel = false;

          this.errMsg = 'err';
        }
      );
    } else {
      this.isChannel = false;
      return;
    }
  }
  reload() {
    window.location.reload();
  }

  goToSearchPage() {
    if (this.searchTerm == '') {
      this.router.navigateByUrl('/home');
      return;
    }
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() =>
        this.router.navigateByUrl(`courses/search?q=${this.searchTerm}`)
      );
  }
  goToCourseByCategoryPage(category: any) {
    this.trigger.closeMenu();
    this.searchTerm = '';
    const id = 'all';
    if (category == 'all') {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() => this.router.navigateByUrl(`/courses/${id}`));
    } else {
      this.router
        .navigateByUrl('/', { skipLocationChange: true })
        .then(() =>
          this.router.navigateByUrl(`/courses/${category.categoryid}`)
        );
    }
  }

  goToCourseBySubCategoryPage(category: any, subcat: any) {
    this.trigger.closeMenu();
    this.searchTerm = '';
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigateByUrl(`/courses/${category.categoryid}/${subcat.categoryname}`));
  }

  goToChannel(channel: any) {
    this.searchTerm = '';
    this.router
      .navigateByUrl(`channels/${channel.channelid}/courses`)
      .then(() => {
        window.location.reload();
      });
  }

  login() {
    this.router.navigateByUrl('login');
  }
  myCourses() {
    this.router.navigateByUrl('home/courses');
  }

  schedule() {
    this.router.navigateByUrl('home/courses/schedule');
  }

  channelClick(data: any) {
    this.allinOneService.channelid = data.channelid;
    this.allinOneService.setCookie('channelid', data.channelid);
    window.location.replace('/');
    // this.router
    //   .navigateByUrl('/', { skipLocationChange: true })
    //   .then(() => window.location.rep());
  }

  openapp() {
    const uid = this.user.userid;
    const url =
      'https://studio.khub.cloud' +
      '/?userid=' +
      (uid.startsWith('+') ? uid.substring(1, uid.length) : uid) +
      '&username=' +
      this.user.username +
      '&atoken=' +
      this.allinOneService.getAtoken();
    window.open(url, '_blank');
  }
}
